import React, { useEffect } from 'react';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import About from './components/About';
import Services from './components/Services';
import Portfolio from './components/Portfolio';
import ContactForm from './components/ContactForm';
import Footer from './components/Footer';
import Vision from './components/Vision';
import Projects from './components/Projects';
import { InlineWidget } from 'react-calendly';
import Testimonials from './components/Testimonials';

function App() {
  return (
    <div className="App">
      {/* <Header /> */}
      <HeroSection />
      <About />
      <Portfolio />
      <Vision />
      <Testimonials />
      {/* <Projects/> */}
      <Services />
      <ContactForm />
      <Footer />
    </div>
  );
}

export default App;

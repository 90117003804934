import React, { useState } from 'react';
import './ContactForm.css';
import { FaFacebook, FaInstagram, FaLinkedin, FaWhatsapp, FaEnvelope, FaPhoneAlt, FaMapMarkerAlt } from 'react-icons/fa';

function ContactForm() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });

  const [formErrors, setFormErrors] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });

  const [successMessage, setSuccessMessage] = useState(''); // State for success message visibility

  // Handle input field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Clear the error when user starts typing
    setFormErrors({ ...formErrors, [name]: '' });
  };

  // Validation function
  const validateForm = () => {
    let errors = {};
    let isValid = true;

    // Check if the name is empty
    if (!formData.name.trim()) {
      errors.name = 'Name is required';
      isValid = false;
    }

    // Validate email format
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!formData.email.trim()) {
      errors.email = 'Email is required';
      isValid = false;
    } else if (!emailPattern.test(formData.email)) {
      errors.email = 'Please enter a valid email address';
      isValid = false;
    }

    // Validate phone number (assume an 11-digit format)
    const phonePattern = /^[0-9]{11}$/;
    if (!formData.phone.trim()) {
      errors.phone = 'Phone number is required';
      isValid = false;
    } else if (!phonePattern.test(formData.phone)) {
      errors.phone = 'Please enter a valid 11-digit phone number';
      isValid = false;
    }

    // Check if the message is empty
    if (!formData.message.trim()) {
      errors.message = 'Message is required';
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return; // Don't submit if the form is invalid
    }

    const apiUrl = 'https://evolvistasolutions.com/submit_message.php'; // URL to your PHP backend

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          name: formData.name,
          email: formData.email,
          phone: formData.phone,
          message: formData.message,
        }),
      });

      if (response.ok) {
        setSuccessMessage('Message sent successfully!'); // Set success message
        setFormData({ name: '', email: '', phone: '', message: '' });

        // Hide success message after 5 seconds
        setTimeout(() => {
          setSuccessMessage('');
        }, 5000);
      } else {
        alert('Failed to send message. Please try again later.');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('An error occurred. Please try again later.');
    }
  };

  return (
    <section id="contact">
      <div className="contact-heading">
        <h1>𝗖𝗼𝗻𝘁𝗮𝗰𝘁 𝗨𝘀</h1>
      </div>

      <div className="contact-wrapper">
        <div className="contact-info">
          <h1 style={{ color: '#FF7F32', fontSize: 45 }}>𝗚𝗲𝘁 𝗬𝗼𝘂𝗿 𝗖𝗼𝗻𝘀𝘂𝗹𝘁𝗮𝘁𝗶𝗼𝗻 𝗧𝗼𝗱𝗮𝘆!</h1>
          <div className="email">
            <a href="mailto:evolvistasolutions@gmail.com" target="_blank" rel="noopener noreferrer">
              <FaEnvelope size={24} style={{ color: 'white' }} />
              <p>𝗲𝘃𝗼𝗹𝘃𝗶𝘀𝘁𝗮𝘀𝗼𝗹𝘂𝘁𝗶𝗼𝗻𝘀@𝗴𝗺𝗮𝗶𝗹.𝗰𝗼𝗺</p>
            </a>
          </div>

          <div className="phone">
            <a href="tel:+923115270001" target="_blank" rel="noopener noreferrer">
              <FaPhoneAlt size={24} />
              <p>+92 3115270001</p>
            </a>
          </div>

          <div className="address">
            <a href="https://maps.app.goo.gl/JtV28ScHitqi5GeK7" target="_blank" rel="noopener noreferrer">
              <FaMapMarkerAlt size={24} />
              <p>2𝗻𝗱 𝗙𝗹𝗼𝗼𝗿, 𝗕𝗶𝗹𝗮𝗹 𝗣𝗹𝗮𝘇𝗮 𝗧 𝗖𝗵𝗼𝘄𝗸, 𝗜𝘀𝗹𝗮𝗺𝗮𝗯𝗮𝗱</p>
            </a>
          </div>

          <div className="social-icons">
            <a href="https://www.facebook.com/profile.php?id=61565441864261" target="_blank" rel="noopener noreferrer">
              <FaFacebook size={30} />
            </a>
            <a href="https://www.instagram.com/evolvistasolutions/" target="_blank" rel="noopener noreferrer">
              <FaInstagram size={30} />
            </a>
            <a href="https://www.linkedin.com/company/evolvista-solutions/" target="_blank" rel="noopener noreferrer">
              <FaLinkedin size={30} />
            </a>
            <a href="https://wa.me/923115270001" target="_blank" rel="noopener noreferrer">
              <FaWhatsapp size={30} />
            </a>
          </div>
        </div>

        <div className="contact-form">
          <h2 style={{color:'white'}}>𝗥𝗲𝗮𝗰𝗵 𝗢𝘂𝘁 𝘁𝗼 𝗨𝘀</h2>
          <p style={{color:'white'}}>𝗛𝗮𝘃𝗲 𝗮 𝗾𝘂𝗲𝘀𝘁𝗶𝗼𝗻 𝗼𝗿 𝗻𝗲𝗲𝗱 𝗲𝘅𝗽𝗲𝗿𝘁 𝗮𝗱𝘃𝗶𝗰𝗲? 𝗙𝗲𝗲𝗹 𝗳𝗿𝗲𝗲 𝘁𝗼 𝗿𝗲𝗮𝗰𝗵 𝗼𝘂𝘁, 𝗮𝗻𝗱 𝘄𝗲 𝘄𝗶𝗹𝗹 𝗮𝘀𝘀𝗶𝘀𝘁 𝘆𝗼𝘂!</p>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="𝗬𝗼𝘂𝗿 𝗡𝗮𝗺𝗲"
          />
          {formErrors.name && <span className="error">{formErrors.name}</span>}

          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="𝗬𝗼𝘂𝗿 𝗘𝗺𝗮𝗶𝗹"
          />
          {formErrors.email && <span className="error">{formErrors.email}</span>}

          <input
            type="text"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            placeholder="𝗬𝗼𝘂𝗿 𝗣𝗵𝗼𝗻𝗲 𝗡𝗼."
          />
          {formErrors.phone && <span className="error">{formErrors.phone}</span>}

          <textarea
            name="message"
            value={formData.message}
            onChange={handleChange}
            rows="4"
            placeholder="𝗬𝗼𝘂𝗿 𝗠𝗲𝘀𝘀𝗮𝗴𝗲"
          />
          {formErrors.message && <span className="error">{formErrors.message}</span>}

          <button onClick={handleSubmit}>𝗦𝗲𝗻𝗱 𝗠𝗲𝘀𝘀𝗮𝗴𝗲</button>

          {/* Success Message */}
          {successMessage && <div className="success-message">{successMessage}</div>}
        </div>
      </div>
    </section>
  );
}

export default ContactForm;


// const timelineData = [
//   {
//     id: 1,
//     title: "Flutter App Development",
//     description: 
//       "Developing and maintaining web applications using React.js and other related technologies. Collaborating with cross-functional teams including designers, product managers, and other developers to create high-quality products.Implementing responsive design and ensuring cross-browser compatibility. Participating in code reviews and providing constructive feedback to other developers.",
//     icon: "☕", // Starbucks logo placeholder
//   },
//   {
//     id: 2,
//     title: "Cloud Services",
//     company: "Tesla",
//     date: "Jan 2021 - Feb 2022",
//     description: [
//       "Developing and maintaining web applications using React.js and other related technologies. Collaborating with cross-functional teams including designers, product managers, and other developers to create high-quality products.Implementing responsive design and ensuring cross-browser compatibility. Participating in code reviews and providing constructive feedback to other developers."
//     ],
//     icon: "🚗", // Tesla logo placeholder
//   },
//   {
//     id: 3,
//     title: "Q/A Testing",
//     company: "Shopify",
//     date: "Jan 2022 - Jan 2023",
//     description: [
//       "Developing and maintaining web applications using React.js and other related technologies. Collaborating with cross-functional teams including designers, product managers, and other developers to create high-quality products.Implementing responsive design and ensuring cross-browser compatibility. Participating in code reviews and providing constructive feedback to other developers."
//     ],
//     icon: "💻", // Shopify logo placeholder
//   },
//   {
//     id: 4,
//     title: "Q/A Testing",
//     company: "Shopify",
//     date: "Jan 2022 - Jan 2023",
//     description: [
//       "Developing and maintaining web applications using React.js and other related technologies. Collaborating with cross-functional teams including designers, product managers, and other developers to create high-quality products.Implementing responsive design and ensuring cross-browser compatibility. Participating in code reviews and providing constructive feedback to other developers."
//     ],
//     icon: "💻", // Shopify logo placeholder
//   },
//   {
//     id: 5,
//     title: "Q/A Testing",
//     company: "Shopify",
//     date: "Jan 2022 - Jan 2023",
//     description: 
//      "Developing and maintaining web applications using React.js and other related technologies. Collaborating with cross-functional teams including designers, product managers, and other developers to create high-quality products.Implementing responsive design and ensuring cross-browser compatibility. Participating in code reviews and providing constructive feedback to other developers.",
//     icon: "💻", // Shopify logo placeholder
//   },
//   {
//     id: 6,
//     title: "Q/A Testing",
//     company: "Shopify",
//     date: "Jan 2022 - Jan 2023",
//     description: 
//      "Developing and maintaining web applications using React.js and other related technologies. Collaborating with cross-functional teams including designers, product managers, and other developers to create high-quality products.Implementing responsive design and ensuring cross-browser compatibility. Participating in code reviews and providing constructive feedback to other developers.",
//     icon: "💻", // Shopify logo placeholder
//   },
//   {
//     id: 7,
//     title: "Q/A Testing",
//     company: "Shopify",
//     date: "Jan 2022 - Jan 2023",
//     description: 
//      "Developing and maintaining web applications using React.js and other related technologies. Collaborating with cross-functional teams including designers, product managers, and other developers to create high-quality products.Implementing responsive design and ensuring cross-browser compatibility. Participating in code reviews and providing constructive feedback to other developers.",
//     icon: "💻", // Shopify logo placeholder
//   },
// ];
import React from "react";
import { motion } from "framer-motion";
import "./Portfolio.css";

const timelineData = [
  {
    id: 1,
    title: "𝗠𝗢𝗕𝗜𝗟𝗘 𝗔𝗣𝗣𝗟𝗜𝗖𝗔𝗧𝗜𝗢𝗡 𝗗𝗘𝗩𝗘𝗟𝗢𝗣𝗠𝗘𝗡𝗧",
    description:
      "𝗪𝗲 𝘀𝗽𝗲𝗰𝗶𝗮𝗹𝗶𝘇𝗲 𝗶𝗻 𝗰𝗿𝗲𝗮𝘁𝗶𝗻𝗴 𝗲𝗻𝗴𝗮𝗴𝗶𝗻𝗴 𝗺𝗼𝗯𝗶𝗹𝗲 𝗮𝗽𝗽𝗹𝗶𝗰𝗮𝘁𝗶𝗼𝗻𝘀 𝗳𝗼𝗿 𝗯𝗼𝘁𝗵 𝗔𝗻𝗱𝗿𝗼𝗶𝗱 𝗮𝗻𝗱 𝗶𝗢𝗦 𝗽𝗹𝗮𝘁𝗳𝗼𝗿𝗺𝘀. 𝗢𝘂𝗿 𝗮𝗽𝗽𝘀 𝗮𝗿𝗲 𝗱𝗲𝘀𝗶𝗴𝗻𝗲𝗱 𝘁𝗼 𝗱𝗲𝗹𝗶𝘃𝗲𝗿 𝘀𝗲𝗮𝗺𝗹𝗲𝘀𝘀 𝗲𝘅𝗽𝗲𝗿𝗶𝗲𝗻𝗰𝗲𝘀 𝘁𝗵𝗮𝘁 𝗰𝗮𝗽𝘁𝗶𝘃𝗮𝘁𝗲 𝘂𝘀𝗲𝗿𝘀 𝗮𝗻𝗱 𝗱𝗿𝗶𝘃𝗲 𝗯𝘂𝘀𝗶𝗻𝗲𝘀𝘀 𝗴𝗿𝗼𝘄𝘁𝗵.",
    icon: "📱", // Starbucks logo placeholder
  },
  {
    id: 2,
    title: "𝗪𝗘𝗕 𝗗𝗘𝗩𝗘𝗟𝗢𝗣𝗠𝗘𝗡𝗧",
    company: "Tesla",
    date: "Jan 2021 - Feb 2022",
    description: [
      "𝗢𝘂𝗿 𝘄𝗲𝗯 𝗱𝗲𝘃𝗲𝗹𝗼𝗽𝗺𝗲𝗻𝘁 𝘁𝗲𝗮𝗺 𝗲𝘅𝗰𝗲𝗹𝘀 𝗶𝗻 𝗰𝗿𝗲𝗮𝘁𝗶𝗻𝗴 𝗱𝘆𝗻𝗮𝗺𝗶𝗰, 𝗿𝗲𝘀𝗽𝗼𝗻𝘀𝗶𝘃𝗲 𝘄𝗲𝗯𝘀𝗶𝘁𝗲𝘀 𝘁𝗮𝗶𝗹𝗼𝗿𝗲𝗱 𝘁𝗼 𝘆𝗼𝘂𝗿 𝗻𝗲𝗲𝗱𝘀. 𝗪𝗵𝗲𝘁𝗵𝗲𝗿 𝘆𝗼𝘂 𝗻𝗲𝗲𝗱 𝗮 𝘀𝗶𝗺𝗽𝗹𝗲 𝘄𝗲𝗯𝘀𝗶𝘁𝗲 𝗼𝗿 𝗮 𝗰𝗼𝗺𝗽𝗹𝗲𝘅 𝘄𝗲𝗯 𝗮𝗽𝗽𝗹𝗶𝗰𝗮𝘁𝗶𝗼𝗻, 𝘄𝗲 𝗱𝗲𝗹𝗶𝘃𝗲𝗿 𝘀𝗼𝗹𝘂𝘁𝗶𝗼𝗻𝘀 𝘁𝗵𝗮𝘁 𝗽𝗿𝗼𝘃𝗶𝗱𝗲 𝗯𝗼𝘁𝗵 𝗳𝘂𝗻𝗰𝘁𝗶𝗼𝗻𝗮𝗹𝗶𝘁𝘆 𝗮𝗻𝗱 𝗮𝗲𝘀𝘁𝗵𝗲𝘁𝗶𝗰 𝗮𝗽𝗽𝗲𝗮𝗹.",
    ],
    icon: "🌐 ", // Tesla logo placeholder
  },
  {
    id: 3,
    title: "𝗦𝗘𝗢 𝗔𝗡𝗗 𝗗𝗜𝗚𝗜𝗧𝗔𝗟 𝗠𝗔𝗥𝗞𝗘𝗧𝗜𝗡𝗚",
    company: "Shopify",
    date: "Jan 2022 - Jan 2023",
    description: [
      "𝗪𝗲 𝗲𝗻𝗵𝗮𝗻𝗰𝗲 𝘆𝗼𝘂𝗿 𝗼𝗻𝗹𝗶𝗻𝗲 𝗽𝗿𝗲𝘀𝗲𝗻𝗰𝗲 𝘄𝗶𝘁𝗵 𝗮 𝗽𝗼𝘄𝗲𝗿𝗳𝘂𝗹 𝗰𝗼𝗺𝗯𝗶𝗻𝗮𝘁𝗶𝗼𝗻 𝗼𝗳 𝗦𝗘𝗢 𝗮𝗻𝗱 𝗱𝗶𝗴𝗶𝘁𝗮𝗹 𝗺𝗮𝗿𝗸𝗲𝘁𝗶𝗻𝗴 𝘀𝘁𝗿𝗮𝘁𝗲𝗴𝗶𝗲𝘀. 𝗢𝘂𝗿 𝗦𝗘𝗢 𝘀𝗲𝗿𝘃𝗶𝗰𝗲𝘀 𝗳𝗼𝗰𝘂𝘀 𝗼𝗻 𝗶𝗺𝗽𝗿𝗼𝘃𝗶𝗻𝗴 𝘆𝗼𝘂𝗿 𝘀𝗲𝗮𝗿𝗰𝗵 𝗲𝗻𝗴𝗶𝗻𝗲 𝗿𝗮𝗻𝗸𝗶𝗻𝗴𝘀 𝗮𝗻𝗱 𝗱𝗿𝗶𝘃𝗶𝗻𝗴 𝗼𝗿𝗴𝗮𝗻𝗶𝗰 𝘁𝗿𝗮𝗳𝗳𝗶𝗰, 𝘄𝗵𝗶𝗹𝗲 𝗼𝘂𝗿 𝗱𝗶𝗴𝗶𝘁𝗮𝗹 𝗺𝗮𝗿𝗸𝗲𝘁𝗶𝗻𝗴 𝘀𝘁𝗿𝗮𝘁𝗲𝗴𝗶𝗲𝘀 𝗮𝗺𝗽𝗹𝗶𝗳𝘆 𝘆𝗼𝘂𝗿 𝗯𝗿𝗮𝗻𝗱’𝘀 𝗿𝗲𝗮𝗰𝗵 𝘁𝗵𝗿𝗼𝘂𝗴𝗵 𝘁𝗮𝗿𝗴𝗲𝘁𝗲𝗱 𝗰𝗮𝗺𝗽𝗮𝗶𝗴𝗻𝘀 𝗮𝗰𝗿𝗼𝘀𝘀 𝘀𝗼𝗰𝗶𝗮𝗹 𝗺𝗲𝗱𝗶𝗮, 𝗰𝗼𝗻𝘁𝗲𝗻𝘁 𝗰𝗿𝗲𝗮𝘁𝗶𝗼𝗻, 𝗮𝗻𝗱 𝗽𝗮𝗶𝗱 𝗮𝗱𝘃𝗲𝗿𝘁𝗶𝘀𝗶𝗻𝗴.",
    ],
    icon: "📈", // Shopify logo placeholder
  },
  // Add other items as needed
  {
    id: 4,
    title: "𝗨𝗜/𝗨𝗫 𝗗𝗘𝗦𝗜𝗚𝗡",
    company: "Shopify",
    date: "Jan 2022 - Jan 2023",
    description: [
      "𝗢𝘂𝗿 𝗱𝗲𝘀𝗶𝗴𝗻 𝗲𝘅𝗽𝗲𝗿𝘁𝘀 𝗰𝗿𝗲𝗮𝘁𝗲 𝗶𝗻𝘁𝘂𝗶𝘁𝗶𝘃𝗲, 𝘂𝘀𝗲𝗿-𝗳𝗿𝗶𝗲𝗻𝗱𝗹𝘆 𝗶𝗻𝘁𝗲𝗿𝗳𝗮𝗰𝗲𝘀 𝘁𝗵𝗮𝘁 𝗲𝗻𝗵𝗮𝗻𝗰𝗲 𝘁𝗵𝗲 𝘂𝘀𝗲𝗿 𝗲𝘅𝗽𝗲𝗿𝗶𝗲𝗻𝗰𝗲. 𝗕𝘆 𝗳𝗼𝗰𝘂𝘀𝗶𝗻𝗴 𝗼𝗻 𝗯𝗼𝘁𝗵 𝗳𝗼𝗿𝗺 𝗮𝗻𝗱 𝗳𝘂𝗻𝗰𝘁𝗶𝗼𝗻, 𝘄𝗲 𝗰𝗿𝗮𝗳𝘁 𝗱𝗲𝘀𝗶𝗴𝗻𝘀 𝘁𝗵𝗮𝘁 𝗲𝗻𝗴𝗮𝗴𝗲 𝘂𝘀𝗲𝗿𝘀 𝗮𝗻𝗱 𝗱𝗿𝗶𝘃𝗲 𝗿𝗲𝘀𝘂𝗹𝘁𝘀.",
    ],
    icon: "🎨", // Shopify logo placeholder
  },
  {
    id: 5,
    title: "𝗚𝗔𝗠𝗘 𝗗𝗘𝗩𝗘𝗟𝗢𝗣𝗠𝗘𝗡𝗧",
    company: "Shopify",
    date: "Jan 2022 - Jan 2023",
    description: [
      "𝗪𝗲 𝗼𝗳𝗳𝗲𝗿 𝗶𝗺𝗺𝗲𝗿𝘀𝗶𝘃𝗲 𝗴𝗮𝗺𝗲 𝗱𝗲𝘃𝗲𝗹𝗼𝗽𝗺𝗲𝗻𝘁 𝘀𝗲𝗿𝘃𝗶𝗰𝗲𝘀, 𝗰𝗿𝗮𝗳𝘁𝗶𝗻𝗴 𝗰𝗮𝗽𝘁𝗶𝘃𝗮𝘁𝗶𝗻𝗴 𝗴𝗮𝗺𝗶𝗻𝗴 𝗲𝘅𝗽𝗲𝗿𝗶𝗲𝗻𝗰𝗲𝘀 𝘁𝗵𝗮𝘁 𝗲𝗻𝗴𝗮𝗴𝗲 𝘂𝘀𝗲𝗿𝘀 𝗮𝗻𝗱 𝗯𝘂𝗶𝗹𝗱 𝗹𝗼𝗻𝗴-𝘁𝗲𝗿𝗺 𝗹𝗼𝘆𝗮𝗹𝘁𝘆. 𝗢𝘂𝗿 𝘀𝗼𝗹𝘂𝘁𝗶𝗼𝗻𝘀 𝗰𝗼𝘃𝗲𝗿 𝗲𝘃𝗲𝗿𝘆𝘁𝗵𝗶𝗻𝗴 𝗳𝗿𝗼𝗺 𝗰𝗼𝗻𝗰𝗲𝗽𝘁 𝘁𝗼 𝗱𝗲𝗽𝗹𝗼𝘆𝗺𝗲𝗻𝘁.",
    ],
    icon: " 🎮", // Shopify logo placeholder
  },
  {
    id: 6,
    title: "𝗔𝗜 𝗦𝗢𝗟𝗨𝗧𝗜𝗢𝗡𝗦",
    company: "Shopify",
    date: "Jan 2022 - Jan 2023",
    description: [
      "𝗦𝘁𝗲𝗽 𝗶𝗻𝘁𝗼 𝘁𝗵𝗲 𝗳𝘂𝘁𝘂𝗿𝗲 𝘄𝗶𝘁𝗵 𝗼𝘂𝗿 𝗶𝗻𝗻𝗼𝘃𝗮𝘁𝗶𝘃𝗲 𝗔𝗜 𝘀𝗼𝗹𝘂𝘁𝗶𝗼𝗻𝘀. 𝗪𝗲 𝗹𝗲𝘃𝗲𝗿𝗮𝗴𝗲 𝗮𝗿𝘁𝗶𝗳𝗶𝗰𝗶𝗮𝗹 𝗶𝗻𝘁𝗲𝗹𝗹𝗶𝗴𝗲𝗻𝗰𝗲 𝘁𝗼 𝗽𝗿𝗼𝘃𝗶𝗱𝗲 𝗮𝗰𝘁𝗶𝗼𝗻𝗮𝗯𝗹𝗲 𝗶𝗻𝘀𝗶𝗴𝗵𝘁𝘀, 𝗲𝗻𝗵𝗮𝗻𝗰𝗲 𝗮𝘂𝘁𝗼𝗺𝗮𝘁𝗶𝗼𝗻, 𝗮𝗻𝗱 𝗼𝗽𝘁𝗶𝗺𝗶𝘇𝗲 𝗼𝗽𝗲𝗿𝗮𝘁𝗶𝗼𝗻𝘀 𝗳𝗼𝗿 𝘆𝗼𝘂𝗿 𝗯𝘂𝘀𝗶𝗻𝗲𝘀𝘀. 𝗙𝗿𝗼𝗺 𝗺𝗮𝗰𝗵𝗶𝗻𝗲 𝗹𝗲𝗮𝗿𝗻𝗶𝗻𝗴 𝗮𝗻𝗱 𝗻𝗮𝘁𝘂𝗿𝗮𝗹 𝗹𝗮𝗻𝗴𝘂𝗮𝗴𝗲 𝗽𝗿𝗼𝗰𝗲𝘀𝘀𝗶𝗻𝗴 𝘁𝗼 𝗱𝗮𝘁𝗮 𝗮𝗻𝗮𝗹𝘆𝘁𝗶𝗰𝘀, 𝗼𝘂𝗿 𝗔𝗜 𝘀𝗲𝗿𝘃𝗶𝗰𝗲𝘀 𝘂𝗻𝗹𝗼𝗰𝗸 𝗻𝗲𝘄 𝗼𝗽𝗽𝗼𝗿𝘁𝘂𝗻𝗶𝘁𝗶𝗲𝘀 𝗳𝗼𝗿 𝗴𝗿𝗼𝘄𝘁𝗵 𝗮𝗻𝗱 𝗲𝗳𝗳𝗶𝗰𝗶𝗲𝗻𝗰𝘆, 𝗵𝗲𝗹𝗽𝗶𝗻𝗴 𝘆𝗼𝘂 𝗱𝗲𝗹𝗶𝘃𝗲𝗿 𝘀𝗺𝗮𝗿𝘁𝗲𝗿, 𝗱𝗮𝘁𝗮-𝗱𝗿𝗶𝘃𝗲𝗻 𝗲𝘅𝗽𝗲𝗿𝗶𝗲𝗻𝗰𝗲𝘀 𝘁𝗼 𝘆𝗼𝘂𝗿 𝗰𝘂𝘀𝘁𝗼𝗺𝗲𝗿𝘀.",
    ],
    icon: "🤖", // Shopify logo placeholder
  },
  {
    id: 7,
    title: "𝗗𝗘𝗩𝗢𝗣𝗦 𝗦𝗘𝗥𝗩𝗜𝗖𝗘𝗦",
    company: "Shopify",
    date: "Jan 2022 - Jan 2023",
    description: [
      "𝗢𝗽𝘁𝗶𝗺𝗶𝘇𝗲 𝘆𝗼𝘂𝗿 𝘀𝗼𝗳𝘁𝘄𝗮𝗿𝗲 𝗽𝗲𝗿𝗳𝗼𝗿𝗺𝗮𝗻𝗰𝗲 𝗮𝗻𝗱 𝘀𝘁𝗿𝗲𝗮𝗺𝗹𝗶𝗻𝗲 𝗼𝗽𝗲𝗿𝗮𝘁𝗶𝗼𝗻𝘀 𝘄𝗶𝘁𝗵 𝗼𝘂𝗿 𝗗𝗲𝘃𝗢𝗽𝘀 𝘀𝗲𝗿𝘃𝗶𝗰𝗲𝘀. 𝗪𝗲 𝗰𝗼𝗺𝗯𝗶𝗻𝗲 𝗱𝗲𝘃𝗲𝗹𝗼𝗽𝗺𝗲𝗻𝘁 𝗮𝗻𝗱 𝗜𝗧 𝗼𝗽𝗲𝗿𝗮𝘁𝗶𝗼𝗻𝘀 𝘁𝗼 𝗮𝗰𝗰𝗲𝗹𝗲𝗿𝗮𝘁𝗲 𝗰𝘆𝗰𝗹𝗲𝘀, 𝗮𝘂𝘁𝗼𝗺𝗮𝘁𝗲 𝗽𝗿𝗼𝗰𝗲𝘀𝘀𝗲𝘀, 𝗮𝗻𝗱 𝗲𝗻𝘀𝘂𝗿𝗲 𝘀𝗺𝗼𝗼𝘁𝗵 𝗱𝗲𝗽𝗹𝗼𝘆𝗺𝗲𝗻𝘁𝘀. 𝗕𝘆 𝗹𝗲𝘃𝗲𝗿𝗮𝗴𝗶𝗻𝗴 𝗰𝗹𝗼𝘂𝗱 𝗽𝗹𝗮𝘁𝗳𝗼𝗿𝗺𝘀 𝗹𝗶𝗸𝗲 𝗔𝗪𝗦, 𝗔𝘇𝘂𝗿𝗲, 𝗮𝗻𝗱 𝗚𝗼𝗼𝗴𝗹𝗲 𝗖𝗹𝗼𝘂𝗱, 𝘄𝗲 𝗲𝗻𝗵𝗮𝗻𝗰𝗲 𝘀𝗰𝗮𝗹𝗮𝗯𝗶𝗹𝗶𝘁𝘆, 𝗿𝗲𝗹𝗶𝗮𝗯𝗶𝗹𝗶𝘁𝘆, 𝗮𝗻𝗱 𝗰𝗼𝘀𝘁-𝗲𝗳𝗳𝗶𝗰𝗶𝗲𝗻𝗰𝘆, 𝗱𝗲𝗹𝗶𝘃𝗲𝗿𝗶𝗻𝗴 𝗳𝗮𝘀𝘁𝗲𝗿, 𝗺𝗼𝗿𝗲 𝗿𝗲𝗹𝗶𝗮𝗯𝗹𝗲 𝗮𝗽𝗽𝗹𝗶𝗰𝗮𝘁𝗶𝗼𝗻𝘀.",
    ],
    icon: "⚙️", // Shopify logo placeholder
  },
];

const TimelineCard = ({ data, isMobile }) => (
  <motion.div
    className={`timeline-item ${isMobile ? "mobile" : ""}`}
    initial={{
      opacity: 0,
      x: isMobile ? 0 : data.position === "right" ? 100 : -100, // Custom start position
    }}
    whileInView={{ opacity: 1, x: 0 }} // Always animate when it enters the viewport
    viewport={{ once: false, amount: 0.2 }} // Trigger when 20% of the item is in view
    transition={{ duration: 1 }} // Duration of the animation
  >
    {isMobile ? (
      <div className="timeline-icon mobile-icon">{data.icon}</div>
    ) : (
      <div className="timeline-icon">{data.icon}</div>
    )}
    <div className="timeline-card">
      <h3 className="timeline-title">{data.title}</h3>
      <div className="timeline-description">
        <h2>{data.description}</h2>
      </div>
    </div>
  </motion.div>
);

const Portfolio = () => {
  const isMobile = window.innerWidth <= 768;

  return (
    <div  id="portfolio" className="timeline-container">
      <h2 className="timeline-header">𝗦𝗘𝗥𝗩𝗜𝗖𝗘𝗦</h2>
      <div className="timeline">
        {timelineData.map((item, index) => (
          <TimelineCard
            key={item.id}
            data={{
              ...item,
              position: index % 2 === 0 ? "left" : "right", // Determine the position of each item
            }}
            isMobile={isMobile}
          />
        ))}
      </div>
    </div>
  );
};

export default Portfolio;

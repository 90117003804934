import React, { useEffect } from "react";
import { motion } from "framer-motion"; // Importing framer-motion for animation
import "./Testimonials.css";
import user1 from './user1.png';


const Testimonials = () => {
  const testimonials = [
    {
      quote: "𝗘𝘃𝗼𝗹𝘃𝗶𝘀𝘁𝗮 𝗦𝗼𝗹𝘂𝘁𝗶𝗼𝗻𝘀 𝗵𝗮𝘀 𝗯𝗲𝗲𝗻 𝗮 𝗳𝗮𝗻𝘁𝗮𝘀𝘁𝗶𝗰 𝗽𝗮𝗿𝘁𝗻𝗲𝗿. 𝗧𝗵𝗲𝗶𝗿 𝗲𝘅𝗽𝗲𝗿𝘁𝗶𝘀𝗲 𝗶𝗻 𝗮𝗽𝗽 𝗱𝗲𝘃𝗲𝗹𝗼𝗽𝗺𝗲𝗻𝘁 𝗮𝗻𝗱 𝗱𝗶𝗴𝗶𝘁𝗮𝗹 𝗺𝗮𝗿𝗸𝗲𝘁𝗶𝗻𝗴 𝗵𝗮𝘀 𝘀𝗶𝗴𝗻𝗶𝗳𝗶𝗰𝗮𝗻𝘁𝗹𝘆 𝗯𝗼𝗼𝘀𝘁𝗲𝗱 𝗼𝘂𝗿 𝗯𝘂𝘀𝗶𝗻𝗲𝘀𝘀. 𝗧𝗵𝗲𝗶𝗿 𝗽𝗿𝗼𝗳𝗲𝘀𝘀𝗶𝗼𝗻𝗮𝗹𝗶𝘀𝗺 𝗮𝗻𝗱 𝗱𝗲𝗱𝗶𝗰𝗮𝘁𝗶𝗼𝗻 𝗲𝗻𝘀𝘂𝗿𝗲 𝘁𝗵𝗮𝘁 𝗲𝘃𝗲𝗿𝘆 𝗽𝗿𝗼𝗷𝗲𝗰𝘁 𝗶𝘀 𝗮 𝘀𝘂𝗰𝗰𝗲𝘀𝘀.",
      title: "𝗖𝗘𝗢 𝗼𝗳 𝗞𝘆𝗿𝗼𝗻𝗶𝘅𝗶𝗮",
      image:  user1,
    },
    {
      quote: "𝗘𝘃𝗼𝗹𝘃𝗶𝘀𝘁𝗮 𝗦𝗼𝗹𝘂𝘁𝗶𝗼𝗻𝘀 𝗵𝗮𝘀 𝗰𝗼𝗻𝘀𝗶𝘀𝘁𝗲𝗻𝘁𝗹𝘆 𝗱𝗲𝗹𝗶𝘃𝗲𝗿𝗲𝗱 𝗵𝗶𝗴𝗵-𝗾𝘂𝗮𝗹𝗶𝘁𝘆 𝘄𝗼𝗿𝗸. 𝗧𝗵𝗲𝗶𝗿 𝗮𝘁𝘁𝗲𝗻𝘁𝗶𝗼𝗻 𝘁𝗼 𝗱𝗲𝘁𝗮𝗶𝗹 𝗮𝗻𝗱 𝗶𝗻𝗻𝗼𝘃𝗮𝘁𝗶𝘃𝗲 𝗮𝗽𝗽𝗿𝗼𝗮𝗰𝗵 𝗵𝗲𝗹𝗽𝗲𝗱 𝘂𝘀 𝗰𝗿𝗲𝗮𝘁𝗲 𝘀𝗲𝗮𝗺𝗹𝗲𝘀𝘀 𝗽𝗿𝗼𝗱𝘂𝗰𝘁𝘀. 𝗧𝗵𝗲𝘆'𝗿𝗲 𝗼𝘂𝗿 𝗴𝗼-𝘁𝗼 𝘁𝗲𝗰𝗵 𝗽𝗮𝗿𝘁𝗻𝗲𝗿.",
      title: "𝗖𝗘𝗢 𝗼𝗳 𝗠𝘂𝘁𝗮𝗻𝘅 𝗧𝗲𝗰𝗵𝗻𝗼𝗹𝗼𝗴𝗶𝗲𝘀",
      image: user1,
    },
    {
      quote: "𝗘𝘃𝗼𝗹𝘃𝗶𝘀𝘁𝗮 𝗦𝗼𝗹𝘂𝘁𝗶𝗼𝗻𝘀 𝘁𝗿𝗮𝗻𝘀𝗳𝗼𝗿𝗺𝗲𝗱 𝗼𝘂𝗿 𝗼𝗻𝗹𝗶𝗻𝗲 𝗽𝗿𝗲𝘀𝗲𝗻𝗰𝗲 𝘄𝗶𝘁𝗵 𝘁𝗵𝗲𝗶𝗿 𝗲𝗳𝗳𝗲𝗰𝘁𝗶𝘃𝗲 𝗦𝗘𝗢 𝗮𝗻𝗱 𝗱𝗲𝘃𝗲𝗹𝗼𝗽𝗺𝗲𝗻𝘁 𝘀𝗲𝗿𝘃𝗶𝗰𝗲𝘀. 𝗧𝗵𝗲𝗶𝗿 𝗿𝗲𝘀𝘂𝗹𝘁𝘀-𝗱𝗿𝗶𝘃𝗲𝗻 𝗮𝗽𝗽𝗿𝗼𝗮𝗰𝗵 𝗺𝗮𝗸𝗲𝘀 𝘁𝗵𝗲𝗺 𝗮 𝘃𝗮𝗹𝘂𝗮𝗯𝗹𝗲 𝗮𝘀𝘀𝗲𝘁 𝘁𝗼 𝗮𝗻𝘆 𝗯𝘂𝘀𝗶𝗻𝗲𝘀𝘀.",
      title: "𝗖T𝗢 𝗼𝗳 𝗞𝘆𝗿𝗼𝗻𝗶𝘅𝗶𝗮",
      image: user1,
    },
  ];

  useEffect(() => {
    const elements = document.querySelectorAll(".testimonial-card");
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          }
        });
      },
      { threshold: 0.5 }
    );

    elements.forEach((element) => {
      observer.observe(element);
    });
  }, []);

  return (
    <section className="testimonials-section">
      <h2 className="section-title">𝗪𝗵𝗮𝘁 𝗢𝘁𝗵𝗲𝗿𝘀 𝗦𝗮𝘆</h2>
      <h1 className="section-header">𝗧𝗲𝘀𝘁𝗶𝗺𝗼𝗻𝗶𝗮𝗹𝘀</h1>
      <div className="testimonials-container">
        {testimonials.map((testimonial, index) => (
          <motion.div
            className="testimonial-card"
            key={index}
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ delay: index * 0.3, duration: 0.6 }}
          >
            <div className="quote-symbol">“</div>
            <p className="testimonial-quote">{testimonial.quote}</p>
            <div className="testimonial-info">
              <img
                src={testimonial.image}
                alt={testimonial.name}
                className="testimonial-avatar"
              />
              <div className="testimonial-details">
                <p className="testimonial-name">{testimonial.title}</p>
                
              </div>
            </div>
          </motion.div>
        ))}
      </div>
    </section>
  );
};

export default Testimonials;

import React, { useEffect, useRef, useState } from 'react';
import './HeroSection.css';
import './Header.css';
import logo5 from './logo5.png';

function HeroSection() {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);
  const videoRef = useRef(null);
  const [menuActive, setMenuActive] = useState(false);
  const toggleMenu = () => {
    setMenuActive((prevState) => !prevState);  // Toggle menu state
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      },
      { threshold: 0.1 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <section className={`hero ${isVisible ? 'visible' : ''}`} ref={sectionRef}>
      {/* Background Video */}
          <video
              ref={videoRef}
              src="./hero.mp4"
              autoPlay
              loop
              muted
              playsInline  // Add this attribute for iOS
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                zIndex: -1,
      }}
    />


      <header className={menuActive ? 'menu-open' : ''}>
            <div className="logo">
              <img src={logo5} alt="Logo" className="logo-img" />
            </div>
            <div className="hamburger" onClick={toggleMenu} aria-label="Toggle menu" role="button">
              <div></div>
              <div></div>
              <div></div>
            </div>
            <nav className={`nav-links ${menuActive ? 'active' : ''}`}>
              <a href="#about" onClick={() => setMenuActive(false)}>Who We Are</a>
              <a href="#portfolio" onClick={() => setMenuActive(false)}>What We Offer</a>
              <a href="#contact" onClick={() => setMenuActive(false)}>Connect with Us</a>
              <a  onClick={() => setMenuActive(false)}>Portfolio</a>
            </nav>
          </header>
      <h1 className={`hero-title ${isVisible ? 'animate' : ''}`}>
        Unlock Your Business's Full Potential with Evolvista Solutions
      </h1>
      <p className={`hero-desc ${isVisible ? 'animate' : ''}`}>
        We turn innovative ideas into impactful digital experiences that drive growth and success in a rapidly evolving world.
      </p>
      {/* <button className="btn">Learn More</button> */}
    </section>
  );
}

export default HeroSection;

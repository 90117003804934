import React,{useEffect,useRef} from "react";
import { FaHtml5, FaCss3Alt, FaJsSquare, FaReact, FaNodeJs, FaDocker, FaGithub, FaFigma } from "react-icons/fa";
import { SiRedux, SiTailwindcss, SiMongodb, SiTypescript, SiJenkins, SiKubernetes, SiTensorflow, SiAdobe, SiShopify, SiGoogle, SiMailchimp } from "react-icons/si";
import { AiFillAndroid, AiFillApple, AiFillGithub } from "react-icons/ai";  // For Android and iOS development tools
import "./Vision.css";

const technologies = [
  { name: "HTML5", icon: <FaHtml5 size={40} color="#E34F26" /> },
  { name: "CSS3", icon: <FaCss3Alt size={40} color="#1572B6" /> },
  { name: "JavaScript", icon: <FaJsSquare size={40} color="#F7DF1E" /> },
  { name: "TypeScript", icon: <SiTypescript size={40} color="#3178C6" /> },
  { name: "ReactJS", icon: <FaReact size={40} color="#61DBFB" /> },
  { name: "Redux", icon: <SiRedux size={40} color="#764ABC" /> },
  { name: "Tailwind CSS", icon: <SiTailwindcss size={40} color="#38B2AC" /> },
  { name: "NodeJS", icon: <FaNodeJs size={40} color="#339933" /> },
  { name: "MongoDB", icon: <SiMongodb size={40} color="#47A248" /> },
  { name: "Git", icon: <FaGithub size={40} color="#181717" /> },
  { name: "Figma", icon: <FaFigma size={40} color="#F24E1E" /> },
  { name: "Docker", icon: <FaDocker size={40} color="#2496ED" /> },
  // Adding more tools for different categories
  { name: "Jenkins", icon: <SiJenkins size={40} color="#D24939" /> }, // DevOps
  { name: "Kubernetes", icon: <SiKubernetes size={40} color="#326CE5" /> }, // DevOps
  { name: "TensorFlow", icon: <SiTensorflow size={40} color="#FF6F00" /> }, // AI
  { name: "Android", icon: <AiFillAndroid size={40} color="#3DDC84" /> }, // App Development
  { name: "iOS", icon: <AiFillApple size={40} color="#000000" /> }, // App Development
  { name: "Adobe XD", icon: <SiAdobe size={40} color="#FF61F6" /> }, // UI/UX Design
  { name: "Shopify", icon: <SiShopify size={40} color="#96BF48" /> }, // Digital Marketing
  { name: "Google Analytics", icon: <SiGoogle size={40} color="#F0A500" /> }, // SEO & Digital Marketing
  { name: "Mailchimp", icon: <SiMailchimp size={40} color="#FDC900" /> }, // Digital Marketing
];

const Vision = () => {
  return (
    <div className="tech-container flex flex-row flex-wrap justify-center gap-10">
      {technologies.map((technology) => (
        <div className="tech-item w-28 h-28" key={technology.name}>
          {technology.icon}
        </div>
      ))}
    </div>
  );
};

export default Vision;

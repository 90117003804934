import React from "react";
import { InlineWidget } from "react-calendly";
import "./Services.css";

const Services = () => {
  return (
    <div className="contact-container">
      <div className="contact-header">
        <h2 className="contact-title">𝗦𝗰𝗵𝗲𝗱𝘂𝗹𝗲 𝗮 𝗙𝗿𝗲𝗲 𝗖𝗮𝗹𝗹</h2>
        <p className="contact-subtitle">
          𝗖𝗵𝗼𝗼𝘀𝗲 𝗮 𝘁𝗶𝗺𝗲 𝘁𝗵𝗮𝘁 𝘄𝗼𝗿𝗸𝘀 𝗯𝗲𝘀𝘁 𝗳𝗼𝗿 𝘆𝗼𝘂, 𝗮𝗻𝗱 𝗹𝗲𝘁'𝘀 𝗱𝗶𝘀𝗰𝘂𝘀𝘀 𝗵𝗼𝘄 𝘄𝗲 𝗰𝗮𝗻 𝗵𝗲𝗹𝗽 𝗴𝗿𝗼𝘄 𝘆𝗼𝘂𝗿 𝗯𝘂𝘀𝗶𝗻𝗲𝘀𝘀.
        </p>
      </div>

      <div className="calendar-container">
        <InlineWidget
          url="https://calendly.com/evolvistasolutions/30min?hide_landing_page_details=1&hide_gdpr_banner=1&background_color=333333&text_color=FFFFFF&primary_color=FF914D&custom_color=FF914D"
          styles={{
            width: "100%",
            height: "100%", // Ensure it takes full container height
            borderRadius: "28px",
            boxShadow: "0 2px 22px rgba(0, 0, 0, 0.1)",
            overflow: "hidden", // Prevent scrollbars
          }}
        />
      </div>
    </div>
  );
};

export default Services;
